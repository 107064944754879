import { SET_USER } from '~/constants'
import { useAppDispatch } from '~/context'
import axios from '~/services/config/axios'
import { getProfile, getToken } from '~/services/loginService'
import { AUTH_LOGIN } from '~/types'
import * as localStorage from '~/utils/localStorage'
import * as sharedData from '~/utils/sharedData'

import { useAlert } from './useAlert'
import { useLoading } from './useLoading'

interface SigInProps {
  email: string
  password: string
  cfToken: string
}

export function useLogin() {
  const dispatch = useAppDispatch()
  const { setAlert } = useAlert()
  const { isLoading } = useLoading()

  async function sigIn({ email, password, cfToken }: SigInProps) {
    isLoading(true)

    try {
      const response = await getToken({ email, password, 'cf-turnstile-response': cfToken })

      if (response.data.access_token) {
        localStorage.save('@makeFunnels/token', response.data.access_token)
        sharedData.setToken(response.data.access_token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`

        const user = await getProfile()
        const useInfo = user?.data?.data
        const { name, email, role, base_domain_id } = useInfo ?? {}

        dispatch({
          type: SET_USER,
          payload: {
            name,
            email,
            role,
            base_domain_id,
          },
        })
        // history.push(`/`)
        setAlert('Login efetuado com sucesso', 'success')
        return AUTH_LOGIN.SUCCESS
      }
    } catch (error) {
      setAlert('Usuário ou senha incorretos, verifique os dados informados.', 'error')
      return AUTH_LOGIN.FAIL
    } finally {
      isLoading(false)
    }
  }

  return {
    sigIn,
  }
}
