import { SET_ALERT, SET_USER, TOGGLE_LOADING } from '~/constants'

import axios from './config/axios'
import { _getProfile } from './mocks/profile'

export const getToken = ({ email, password, 'cf-turnstile-response': cfToken }) =>
  axios.post('/auth/login', {
    email,
    password,
    'cf-turnstile-response': cfToken,
  })

// export const getProfile = () => axios.get("/auth/me")
export const getProfile = _getProfile

export const logout = () => axios.get('/auth/logout')

export const recover = (token, email, data) =>
  axios.post(`users/verify/${token}?email=${email}`, data)

export const resetPassword = ({ email, cfToken }) =>
  axios.post('auth/reset_password', {
    email,
    'cf-turnstile-response': cfToken,
  });


export async function FetchProfile(dispatch) {
  dispatch({
    type: TOGGLE_LOADING,
    payload: { show: true, background: true },
  })
  try {
    const profile = await getProfile()
    const {
      data: {
        data: { ...allValues },
      },
    } = profile
    const { name, email, role, base_domain_id } = allValues
    dispatch({
      type: SET_USER,
      payload: {
        name,
        email,
        role,
        base_domain_id,
      },
    })
  } catch (error) {
    dispatch({
      type: SET_ALERT,
      payload: {
        open: true,
        message: 'Houve um problema, tente novamente!',
        variant: 'error',
      },
    })
  }
  dispatch({
    type: TOGGLE_LOADING,
    payload: { show: false, background: false },
  })
}
