import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useLogin } from '~/hooks/useLogin'

import LoginView from './loginView'
import { useCaptchaCloudflare } from '~/hooks/useCaptchaCloudflare'

export default function LoginController() {
  const [credential, setCredential] = useState({
    email: '',
    password: '',
  })
  let history = useHistory()
  const { sigIn } = useLogin()
  const { cfToken, isCaptchaLoading } = useCaptchaCloudflare()

  function handleChange(event) {
    setCredential({
      ...credential,
      [event.target.name]: event.target.value,
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()

     if (!cfToken) {
          return dispatch({
            type: SET_ALERT,
            payload: {
              open: true,
              message: 'Por favor, complete o CAPTCHA.',
              variant: 'error',
            },
          })
        }

    sigIn({
      email: credential.email,
      password: credential.password,
      'cf-turnstile-response': cfToken,
    }).then((res) => {
      if (res === 'success') return history.push(`/`)
    })
  }

  return (
    <LoginView
      email={credential.email}
      password={credential.password}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isCaptchaLoading={isCaptchaLoading}
    />
  )
}
