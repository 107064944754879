import { useEffect, useRef, useState } from 'react';

export const useCaptchaCloudflare = () => {
  const responseToken = useRef(null);
  const captchaLoaded = useRef(false);
  const [isCaptchaLoading, setCaptchaLoading] = useState(true);

  useEffect(() => {
    if (captchaLoaded.current) return;

    if (window.turnstile) {
      window.turnstile.render('#turnstileEl', {
        sitekey: process.env.REACT_APP_TURNSTILE_SITEKEY,
        theme: 'light',
        callback: (token) => {
          responseToken.current = token;
          setCaptchaLoading(false);  
        },
        'error-callback': (err) => {
          console.error(`Erro no CAPTCHA: ${err}`);
        },
      });
      captchaLoaded.current = true;
    }
  }, []);

  return {
    cfToken: responseToken.current || '',
    isCaptchaLoading,
  };
};
