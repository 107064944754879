import { useMediaQuery, useTheme } from '@material-ui/core'
import React, { ElementType, useState } from 'react'

import { LoginContent } from './LoginContent'
import { LoginDrawer } from './LoginDrawer'
import { LoginModal } from './LoginModal'

interface LoginDialogProps {
  isOpen: boolean
  handleClose: () => void
  handleSubmit: ({ email, password, cfToken }: { email: string; password: string; cfToken:string }) => void
  ContentComponent?: ElementType
  HeadLine?: ElementType
}

export function LoginDialog({
  isOpen,
  handleClose,
  handleSubmit,
  ContentComponent = LoginContent,
  HeadLine
}: LoginDialogProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [formState, setFormState] = useState({
    email: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
  })

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value, name = '' } = e.target
    setFormState((prevValues) => ({
      ...prevValues,
      [name]: {
        ...prevValues[name as 'email' | 'password'],
        value,
      },
    }))
  }

  const validateHandleSubmit = (props: { email: string; password: string; cfToken: string }) => {
    let hasError = false

    const setError = (key: keyof typeof formState, value: boolean) =>
      setFormState((prevformState) => ({
        ...prevformState,
        [key]: {
          ...prevformState[key],
          error: value,
        },
      }))

    if (!formState.email.value) {
      hasError = true
      setError('email', true)
    } else {
      setError('email', false)
    }

    if (!formState.password.value) {
      hasError = true
      setError('password', true)
    } else {
      setError('password', false)
    }

    if (!hasError) return handleSubmit({ ...props })
  }

  const RenderComponent = isMobile ? LoginDrawer : LoginModal

  return (
    <RenderComponent
      isOpen={isOpen}
      handleClose={handleClose}
      handleChange={handleChange}
      formState={formState}
      handleSubmit={validateHandleSubmit}
      ContentComponent={ContentComponent}
      HeadLine={HeadLine}
    />
  )
}
