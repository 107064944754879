import React, { useState } from 'react'

import { SET_ALERT } from '~/constants'
import { useAppDispatch } from '~/context'
import { resetPassword } from '~/services/loginService'

import View from './ForgotPasswordView'
import { useCaptchaCloudflare } from '~/hooks/useCaptchaCloudflare'

function ForgotPassword() {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [messageState, setMessageState] = useState('')
  const { cfToken, isCaptchaLoading } = useCaptchaCloudflare()

  function handleChange(e) {
    const { value } = e.target

    setEmail(value)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (!email.length)
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Campo Email é obrigatório',
          variant: 'error',
        },
      })

    if (!cfToken) {
      return dispatch({
        type: SET_ALERT,
        payload: {
          open: true,
          message: 'Por favor, complete o CAPTCHA.',
          variant: 'error',
        },
      })
    }

    try {
      await resetPassword({ email, cfToken })
    } catch (err) {
      return setMessageState('error')
    }

    return setMessageState('success')
  }
  return (
    <View
      state={{ email, messageState }} 
      actions={{ handleSubmit, handleChange }}  
      isCaptchaLoading={isCaptchaLoading}  
    />
  );
}

export default ForgotPassword
